import React from "react";
import Lottie from 'react-lottie';
import styled from "styled-components";
import automatedAnimation from '../../images/features/automated.json';
import notificationsAnimation from '../../images/features/notifications.json';
import securityAnimation from '../../images/features/security.json';
import simpleAnimation from '../../images/features/simple.json';
import { Container, Section } from "../global";

const simpleAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: simpleAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const notificationsAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: notificationsAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const securityAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: securityAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const automatedAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: automatedAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Features = () => {
  
  return (
    <Section id="features">
      <StyledContainer>
        <Subtitle>Features</Subtitle>
        <SectionTitle>Sitting circle management</SectionTitle>
        <FeaturesGrid>
          <FeatureItem>
            <Lottie
              options={simpleAnimationOptions}
              height={100}
              width={100}/>
            <FeatureTitle>Simple</FeatureTitle>
            <FeatureText>
              Quickly and easily add a request for a pet or baby sitter.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <Lottie
              options={notificationsAnimationOptions}
              height={100}
              width={100}/>
            <FeatureTitle>Notifications</FeatureTitle>
            <FeatureText>
              Receive alerts when a request is made or yours is accepted.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <Lottie
              options={securityAnimationOptions}
              height={100}
              width={100}/>
            <FeatureTitle>Security</FeatureTitle>
            <FeatureText>
              Your data is always safe with us, and only the people in your circle can see you.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <Lottie
              options={automatedAnimationOptions}
              height={100}
              width={100}/>
            <FeatureTitle>Automated</FeatureTitle>
            <FeatureText>
              Log the times and we'll work out all the points for you.
            </FeatureText>
          </FeatureItem>
        </FeaturesGrid>
      </StyledContainer>
    </Section>
  )
}

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
