import React from "react";
import styled from "styled-components";
import friendsImage from "../../images/product/friends_online.svg";
import notificationsImage from "../../images/product/push_notifications.svg";
import { Container, Section } from "../global";

const HowItWorks = () => {
  
  return (
    <StyledSection id="how it works">
      <StyledContainer>
        <Subtitle>How It Works</Subtitle>
        <SectionTitle>Simply sign up, invite friends and start sitting</SectionTitle>
        <FeaturesGrid>
          <FeatureItem>
            <FeatureTitle>1. Sign up</FeatureTitle>
            <FeatureText>
              We just need a few details and then you're ready to go.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>2. Set up a circle</FeatureTitle>
            <FeatureText>
              Your first circle is automatically created for you, just set a few preferences.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>3. Invite friends</FeatureTitle>
            <FeatureText>
              Add your friends e-mail addresses for them to join your circle.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <ImageWrapper>
                <StyledImage src={friendsImage} alt="Friends" />
                <br />
            </ImageWrapper>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>4. Request a sitter</FeatureTitle>
            <FeatureText>
              Simply add a request and your circle will be notified
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>5. Accept a request</FeatureTitle>
            <FeatureText>
              Once a friend accepts your request you'll get a notification
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>6. Go out and have fun</FeatureTitle>
            <FeatureText>
              You're friend comes to sit for you whilst you get to go out.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>7. Easy time logging</FeatureTitle>
            <FeatureText>
              When you've had your fun, either you or your friend just need to log the time they sat for.
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <FeatureTitle>8. Track your points</FeatureTitle>
            <FeatureText>
              Automatically your circles points will be modified after each sitting, make sure you return the favour!
            </FeatureText>
          </FeatureItem>
          <FeatureItem>
            <ImageWrapper>
                <StyledImage src={notificationsImage} alt="Notifications" />
                <br />
            </ImageWrapper>
          </FeatureItem>
        </FeaturesGrid>
      </StyledContainer>
    </StyledSection>
  )
}

export default HowItWorks

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%);
`

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled.img`
  width: 300px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 200px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 200px;
  }
`
