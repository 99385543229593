import React from "react"
import styled from "styled-components"
import appImage from "../../images/product/mobile_app.svg"
import { Container, Section } from "../global"

const Apps = () => {
  return (
    <Section id="apps">
      <Container>
        <Flex>
          <AppsTextGroup>
            <Subtitle>Apps</Subtitle>
            <h1>
              Coming soon to iOS,
              <br />
              and Android
            </h1>
            <h2>
              In the meantime we have a web app you can use from your browser easily from a computer or phone.
            </h2>
          </AppsTextGroup>
          <ImageWrapper>
            <StyledImage src={appImage} alt="Apps" />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </Section>
  )
}

export default Apps

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const AppsTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.font_size.regular};
    color: ${props => props.theme.color.secondary};
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled.img`
  width: 500px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 300px;
  }
`
