import React from "react"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Apps from "../components/sections/apps"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"
import Header from "../components/sections/header"
import HowItWorks from "../components/sections/howitworks"
import image from "../images/product/friends_online.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" image={image} />
    <Navigation />
    <Header />
    <Features />
    <HowItWorks />
    <Apps />
    <GetStarted />
    <Footer />
  </Layout>
)

export default IndexPage
